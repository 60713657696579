import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { filter, mergeMap, take, tap } from 'rxjs'
import { formatBranchName } from '../../helpers'
import { AUTH_SERVICE, BaseAuthService } from '../../injection-tokens'
import { COMMON_ENVIRONMENT_TOKEN, ICommonEnvironment } from '../../injection-tokens/common-environment'
import { BaseCustomerService, CUSTOMER_SERVICE } from '../../injection-tokens/customer-service'
import { ISupportTicket } from '../../interfaces/i-support-ticket'
import { MaterialModule } from '../../material/material.module'
import { AlertService } from '../../services/alert.service'
import { SupportService } from '../../services/support.service'
import { UploadComponent } from '../upload/upload.component'

@Component({
  selector: 'lib-create-zendesk-ticket',
  standalone: true,
  templateUrl: './create-zendesk-ticket.component.html',
  styleUrls: ['./create-zendesk-ticket.component.scss'],
  imports: [TranslateModule, MaterialModule, FormsModule, CommonModule, UploadComponent]
})
export class CreateZendeskTicketComponent {
  translate = inject<TranslateService>(TranslateService)
  productLines = ['TITLE.republic', 'adSystems', 'hardware', 'stanley'].map((productLine) =>
    this.translate.instant(productLine)
  )

  questionRelations = [
    'estimate',
    'requestForQuote',
    'applyingAQuote',
    'productConfiguration',
    'submittingOrder',
    'keying'
  ].map((questionRelatedTo) => this.translate.instant(questionRelatedTo))

  authService = inject<BaseAuthService>(AUTH_SERVICE)
  environment = inject<ICommonEnvironment>(COMMON_ENVIRONMENT_TOKEN)
  customerService = inject<BaseCustomerService>(CUSTOMER_SERVICE)
  alertService = inject(AlertService)
  supportService = inject(SupportService)
  dialogRef = inject<MatDialogRef<CreateZendeskTicketComponent>>(MatDialogRef<CreateZendeskTicketComponent>)

  ticket: ISupportTicket = {
    attachments: [],
    description: '',
    issueTitle: '',
    productLine: '',
    questionRelatedTo: '',
    emailAddress: '',
    firstName: '',
    lastName: ''
  }

  loading = false

  submitForm() {
    this.loading = true
    this.authService.userProfile$
      .pipe(
        filter((user) => !!user),
        take(1),
        tap((user) => {
          this.ticket.emailAddress = user.email ?? ''
          this.ticket.firstName = user.given_name ?? ''
          this.ticket.lastName = user.family_name ?? ''
          this.ticket.parentCompany = formatBranchName(this.customerService?.parentCompany)
          this.ticket.companyBranch = formatBranchName(this.customerService?.selectedBranch)
          this.ticket.description = this.ticket.description?.length
            ? this.ticket.description
            : 'No description provided'
        }),
        mergeMap(() => this.supportService.sendSupportTicket(this.ticket))
      )
      .subscribe({
        next: (success) => {
          this.loading = false
          if (success) {
            this.alertService.openAlert(
              {
                title: 'SUCCESSES.submittedSupportMessage',
                description: 'SUCCESSES.supportMessageFollowup',
                state: 'success'
              },
              5 * 1000
            )
            this.dialogRef.close()
          } else {
            this.alertService.openAlert(
              {
                title: 'ERROR.failedToSubmitSupportRequest',
                description: 'ERROR.emailSupportInboxWithIssue',
                state: 'error',
                secondaryAction: {
                  label: 'ACTIONS.sendSupportEmail',
                  action: () => this.sendSupportEmail()
                }
              },
              10 * 1000
            )
          }
        }
      })
  }

  sendSupportEmail() {
    this.dialogRef.close()
    window.location.href = `mailto:${this.environment.supportEmail}?subject=${this.ticket.issueTitle}&body=${this.ticket.description}`
  }
}
