<div class="header">
  <h2>{{ 'TITLE.oeoSupportTicket' | translate }}</h2>
  <button mat-icon-button [mat-dialog-close]="true">
    <span class="material-symbols-outlined"> close </span>
  </button>
</div>

<ng-container *ngIf="!loading; else Loading">
  <form mat-dialog-content #ticketForm="ngForm">
    <div class="details">
      <mat-form-field #productLine>
        <mat-label>{{ 'LABEL.productLine' | translate }}</mat-label>
        <mat-select name="product-line" required [(ngModel)]="ticket.productLine">
          <mat-option *ngFor="let product of productLines" [value]="product">{{ product }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'LABEL.questionRelatedTo' | translate }}</mat-label>
        <mat-select name="question-related-to" required [(ngModel)]="ticket.questionRelatedTo">
          <mat-option *ngFor="let questionRelatedTo of questionRelations" [value]="questionRelatedTo">{{
            questionRelatedTo
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="full-width">
      <mat-form-field class="full-width">
        <mat-label>{{ 'LABEL.issueTitle' | translate }}</mat-label>
        <input matInput type="text" maxlength="500" name="issue-title" required [(ngModel)]="ticket.issueTitle" />
      </mat-form-field>
    </div>
    <div class="full-width">
      <mat-form-field class="full-width">
        <mat-label>{{ 'LABEL.provideDetailedDescription' | translate }}</mat-label>
        <textarea
          name="description"
          matInput
          rows="6"
          [(ngModel)]="ticket.description"
          [placeholder]="'typeHereToAddText' | translate"
          #description
          maxlength="2000"
        ></textarea>
        <mat-hint class="text-limit">{{ description.value.length }}/2000</mat-hint>
      </mat-form-field>
    </div>
    <div class="upload">
      <mat-label>{{ 'LABEL.provideScreenshot' | translate }}</mat-label>
      <oa-upload
        [acceptedFileTypes]="['png', 'jpg', 'gif']"
        [maxFileSize]="3"
        [multiple]="true"
        (file)="ticket.attachments = $event"
      >
      </oa-upload>
    </div>
  </form>
  <div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]="true">{{ 'ACTIONS.close' | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="ticketForm.invalid" (click)="submitForm()">
      {{ 'ACTIONS.submit' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #Loading>
  <div class="loading">
    <mat-spinner diameter="100"></mat-spinner>
  </div>
</ng-template>
